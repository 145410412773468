:root {
  --theme: #D95D46;
  --accent: #3E7652;
  --primary: #373649;
  --secondary: #9B9898;
  --grayback: #DFDFDF;
  --themeback: #FDFBE5;
  --background: #ffffff;
  --font: "fot-tsukuardgothic-std", sans-serif;

  /* if debug */
  /* --primary: #ffffff;
  --background: #000000; */
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
} 

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

html,
body {
  max-width: 100vw;
  line-height: 1.25;
  overscroll-behavior-y: none;
}

body {
  color: var(--primary);
  background: var(--background);
  font-family: var(--font);
  font-weight: 400;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,ol,li {
  list-style: none;
}

input {
  background: none;
	border: none;
	border-radius: 0;
	outline: none;
}

/* Fonts */

.b48 {
  font-family: var(--font);
  font-weight: 700;
  font-size: 48px;
}

.b24 {
  font-family: var(--font);
  font-weight: 700;
  font-size: 24px;
}

.b16 {
  font-family: var(--font);
  font-weight: 700;
  font-size: 16px;
}

.r18 {
  font-family: var(--font);
  font-weight: 400;
  font-size: 18px;
}

.r14 {
  font-family: var(--font);
  font-weight: 400;
  font-size: 14px;
}

.r10 {
  font-family: var(--font);
  font-weight: 400;
  font-size: 10px;
}

.t-left {
  text-align: left;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 2;
}

.lh-16 {
  line-height: 16px;
}

.lh-20 {
  line-height: 20px;
}

.lh-30 {
  line-height: 30px;
}

.lh-32 {
  line-height: 32px;
}

.lh-36 {
  line-height: 36px;
}

.lh-46 {
  line-height: 46px;
}

.l-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-all;
  overflow: hidden;
}

.l-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
}

.l-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
  overflow: hidden;
}

.l-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  word-break: break-all;
  overflow: hidden;
}

/* Colors */

.theme {
  color: var(--theme);
}

.accent {
  color: var(--accent);
}

.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.red {
  color: red;
}

.reverse {
  color: var(--background);
}

.themeback {
  background: var(--theme);
}

.accentback {
  background: var(--accent);
}

.grayback {
  background: var(--grayback);
}

.back {
  background: var(--background);
}

/* Layouts */

.full {
  width: 100%;
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-25 {
  height: 25%;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.row {
  display: flex;
}

.row.top {
  align-items: start;
}

.row.center {
  align-items: center;
  justify-content: center;
}

.row.bottom {
  align-items: end;
}

.row.start {
  justify-content: start;
}

.row.between {
  justify-content: space-between;
}

.row.around {
  justify-content: space-around;
}

.row.wrap {
  flex-wrap: wrap;
}

.col {
  display: flex;
  flex-flow: column;
}

.col.left {
  align-items: start;
}

.col.center {
  align-items: center;
  justify-content: center;
}

.col.right {
  align-items: end;
}

.col.start {
  justify-content: start;
}

.col.between {
  justify-content: space-between;
}

.col.around {
  justify-content: space-around;
}

.g-5 {
  gap: 5px;
}

.g-10 {
  gap: 10px;
}

.g-15 {
  gap: 15px;
}

.g-20 {
  gap: 20px;
}

.g-25 {
  gap: 25px;
}

.g-30 {
  gap: 30px;
}

.g-35 {
  gap: 35px;
}

.g-40 {
  gap: 40px;
}

.g-50 {
  gap: 50px;
}

.g-60 {
  gap: 60px;
}

.g-80 {
  gap: 80px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

/* Effects */

.eyecatch {
  width: 100%;
  aspect-ratio: 1.78;
  vertical-align: top;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  text-wrap: nowrap;
}

.visible-x {
  overflow-x: visible;
}

.scroll-x {
  overflow-x: scroll;
}

.clipped {
  overflow: hidden;
}

.accent-line {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--accent);
}

.reverse-dashed {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed var(--background);
}

.secondary-dashed {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed var(--secondary);
}

.accent-border {
  width: 100%;
  border-bottom: 1px solid var(--accent);
}

.border {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.theme-stroke {
  border: 1px solid var(--theme);
  border-radius: 3px;
}

.accent-stroke {
  border: 1px solid var(--accent);
  border-radius: 3px;
}

.up:hover {
  transform: translateY(-5px);
}

.slash {
  background-image: linear-gradient(var(--background), var(--background));
  background-size: 0 1px;
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-out;
}

.slash:hover {
  background-size: 100% 1px;
}

/* blog-content */

.blog-content {
  display: flex;
  flex-flow: column;
  gap: 35px;
  font-family: var(--font);
  line-height: 1.8;
}

.blog-content h1 {
  font-weight: 700;
  font-size: 30px;
}

@media screen and (min-width: 1080px) {
  .blog-content h1 {
    font-size: 36px;
  }
}

.blog-content h2 {
  font-weight: 700;
  font-size: 28px;
}

.blog-content h3 {
  font-weight: 700;
  font-size: 24px;
}

.blog-content h4 {
  font-weight: 700;
  font-size: 16px;
}

.blog-content h5 {
  font-weight: 700;
  font-size: 16px;
}

.blog-content p {
  font-weight: 400;
  font-size: 16px;
}

.blog-content blockquote {
  font-style: italic;
  border-left: 2px solid var(--secondary);
  padding: 20px 0px 20px 20px;
}

.blog-content code {
  color: var(--background);
  background: var(--primary);
  padding: 5px;
  border-radius: 4px;
}

.blog-content pre {
  color: var(--background);
  background: var(--primary);
  padding: 20px;
  border-radius: 4px;
}

.blog-content a {
  text-decoration: underline;
}

.blog-content img {
  width: 100%;
  height: auto;
}

.blog-content figcaption {
  font-size: 14px;
  font-style: italic;
}

.blog-content ul li {
  list-style: disc outside;
  margin-left: 20px;
}

.blog-content ol li {
  list-style: decimal outside;
  margin-left: 20px;
}

.blog-content li {
  margin: 10px 0px;
}

.blog-content table, th, td {
  text-align: left;
  border-collapse: collapse;
  border: 1px solid var(--secondary);
}

.blog-content th {
  background: var(--grayback);
  padding: 10px;
}

.blog-content th p {
  font-weight: 700;
}

.blog-content td {
  padding: 10px;
}

/* containers */

.leaflet-container.main-map {
  width: 100%;
  height: 100dvh;
}

.leaflet-container.nearby-map {
  width: 100%;
  height: auto;
  aspect-ratio: 1.78;
  border-radius: 10px;
}

.leaflet-container.single-map {
  width: 100%;
  height: auto;
  aspect-ratio: 1.78;
  border-radius: 10px;
}

@media screen and (min-width: 1080px) {
  .leaflet-container.main-map {
    height: calc(100dvh - 80px);
    width: 100%;
  }

  .leaflet-container.nearby-map {
    width: 750px;
  }
}

.nearby-sheet-opener {
  width: 100%;
  height: 12px;
  margin-top: 20px;
  cursor: pointer;
  touch-action: none;
}

.nearby-sheet {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--background);
  border: 2px solid var(--accent);
  border-radius: 15px 15px 0 0;
  padding: 0px 15px 20px;
  z-index: 1000;
  touch-action: none;
}

.nearby-sheet.opened {
  touch-action: pan-y;
}

.nearby-sheet.closed {
  touch-action: none;
}

.nearby-sheet .eyecatch {
  width: auto;
  height: 78px;
}

@media screen and (min-width: 541px) {
  .nearby-sheet {
    padding: 0px 20px 20px;
  }

  .nearby-sheet .eyecatch {
    width: auto;
    height: 106px;
  }
}

@media screen and (min-width: 1080px) {
  .nearby-sheet-opner {
    position: absolute;
    top: calc(50vh - 50px);
    right: 230px;
    width: 100px;
    height: 100px;
    padding-left: 20px;
    padding-right: 60px;
    background: var(--background);
    border: 2px solid var(--accent);
    border-radius: 50px;
    z-index: 1000;
    cursor: pointer;
    transform: translateX(280px);
  }
  
  .nearby-sheet-opner.opened {
    transform: translateX(0);
    animation: side-bar-open 0.4s ease-out;
  }
  
  .nearby-sheet-opner.closed {
    transform: translateX(280px);
    animation: side-bar-close 0.4s ease-out;
  }
  
  .nearby-sheet {
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    height: 100dvh;
    border-width: 2px 0 2px 2px;
    border-radius: 15px 0 0 15px;
    padding: 30px 30px 20px;
    gap: 15px;
    z-index: 1000;
    transform: translateX(280px);
  }

  .nearby-sheet .eyecatch {
    width: 100%;
    height: auto;
  }
  
  .nearby-sheet.opened {
    transform: translateX(0);
    animation: side-bar-open 0.4s ease-out;
  }
  
  .nearby-sheet.closed {
    transform: translateX(280px);
    animation: side-bar-close 0.4s ease-out;
  }
  
  @keyframes side-bar-open {
    from { 
      transform: translateX(280px);
    }
    to { 
      transform: translateX(0); 
    }
  }
  
  @keyframes side-bar-close {
    from { 
      transform: translateX(0);
    }
    to { 
      transform: translateX(280px); 
    }
  }
}

.header {
  display: flex;
  align-items: center;
  position: absolute;
  top: 30px;
  width: 100%;
  color: var(--background);
  padding-left: 30px;
  padding-right: 80px;
  z-index: 1001;
}

@media screen and (max-width: 540px) {
  .header {
    top: 15px;
    padding-left: 15px;
    padding-right: 65px;
  }
}

@media screen and (min-width: 1080px) {
  .header {
    background: var(--theme);
    position: relative;
    top: 0;
    height: 80px;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 999;
  }
}

.header-menu {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 1;
  cursor: pointer;
  z-index: 1001;
}

@media screen and (max-width: 540px) {
  .header-menu {
    top: 15px;
    right: 15px;
  }
}

.header-cover {
  position: fixed;
  top: 0px;
  left: 0px; 
  width: 100vw;
  height: 100dvh;
  background: var(--secondary);
  z-index: 1001;
  display: none;
}

.header-cover.opened {
  display: block;
  opacity: 0.9;
  animation: header-cover-open 0.3s ease-out;
}

.header-cover.closed {
  display: none;
  animation: header-cover-close 0.3s ease-out;
}

@keyframes header-cover-open {
  from { 
    display: none;
    opacity: 0;
    transform: translateY(80px);
  }
  to { 
    display: block;
    opacity: 0.9;
    transform: translateY(0); 
  }
}

@keyframes header-cover-close {
  from { 
    display: block;
    opacity: 0.9;
    transform: translateY(0);
  }
  to { 
    display: none;
    opacity: 0;
    transform: translateY(80px);
  }
}

.footer {
  position: relative;
  color: var(--background);
  background: var(--theme);
  padding: 30px 30px;
}

@media screen and (max-width: 540px) {
  .footer {
    padding: 30px 15px;
  }
}

.container {
  width: 100%;
  max-width: 1080px;
  padding: 100px 30px 200px;
  margin: 0px auto;
}

@media screen and (max-width: 540px) {
  .container {
    padding: 100px 15px 200px;
  }
}

.blog-container {
  max-width: 750px;
  margin: auto;
}

/* components */

.search-bar {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--background);
  border: 1px solid var(--accent);
  border-radius: 6px;
  padding: 0px 10px;
}

.paging-button {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background: var(--accent);
  color: var(--background);
  font-family: var(--font);
  font-weight: 700;
  font-size: 24px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.sahanzi-logo {
  position: absolute;
  left: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 20px;
  background: var(--background);
  border-radius: 2px;
  box-shadow: 0px 2px 3px var(--grayback);
}

@media screen and (max-width: 540px) {
  .sahanzi-logo {
    left: 15px;
  }
}